// import { Tag } from 'element-ui'
import request from '../../utils/request'

interface Tenant<T> {
  nature?: T;
  grade?: T;
  name?: T;
  size?: number;
  current?: number;
}
/**获取租户数据 */
export function getTenantData(data: Tenant<string>): Promise<any> {
  return request({
    method: 'GET',
    url: 'manage/tenant/list',
    type: 'params',
    data
  })
}
interface Form<T> {
  address: T;
  city: T;
  code: T;
  district: T;
  grade: T;
  img: T;
  jkglTenantDetail: {
    tenantDetailImage: T;
    tenantDetailInfo: T;
    tenantDetailJobTime: T;
    tenantDetailLat: T;
    tenantDetailLong: T;
    tenantDetailSort: T;
    tenantDetailTelephone: T;
  };
  name: T;
  nature: T;
  optional: T;
  province: T;
  status: T;
}
/**新增或者更新租户信息,form就是单个菜单节点都甩到后端，参数会比定义的接口类多一个ID */
export function addUpdateTenant(form: Form<string>): Promise<any> {
  return request({
    url: 'manage/tenant/submit',
    method: 'POST',
    data: form
  })
}

/**点击菜单节点，根据id获取信息 */
export function getNodeData(id: string): Promise<any> {
  return request({
    method: 'GET',
    url: 'manage/tenant/detail/' + id,
  })
}

/**根据菜单节点id进行删除操作 */
export function deleteTenant(ids: number[]) {
  return request({
    url: 'manage/tenant/remove?ids=' + ids.join(','),
    method: 'POST',
    type: 'params'
  })
}
/**上传图片 */
export function uploadImg(file: FormData): Promise<any> {
  return request({
    url: 'manage/manage/upload?uploadPath=/tenant',
    method: 'POST',
    data: file
  })
}

interface Arrange<T> {
  current: number;
  size: number;
  startTime?: T;
  endTime?: T;

}
/**获取排班周期数据 */
export function arrangeList(data: Arrange<string>): Promise<any> {
  return request({
    url: 'manage/roster/list',
    method: 'get',
    type: 'params',
    data
  })
}
/**根据周期ID获取时段数据 */
export function arrangeDetail(id: number): Promise<any> {
  return request({
    url: 'manage/roster/detail/' + id,
    method: 'get',
    type: 'params',
  })
}
interface RosterDetailList<T> {
  checkNumPeople?: T;
  startTime?: T;
  endTime?: T;
  groupCheckNumPeople?: T;
  rosterId?: T;
  timePeriod?: T;
}
interface Roster<T> {
  startTime: T;
  endTime: T;
  tenantId: number;
  rosterDetailList: RosterDetailList<T>[];
}
/**新增或者修改 */
export function submitRoster(data: Roster<string>): Promise<any> {
  return request({
    url: 'manage/roster/submit',
    method: 'post',
    data
  })
}
interface Calendar<T> {
  tenantId: T;
  year: string;
}
/**获取日历数据 */
export function getCalendar(data: Calendar<number>): Promise<any> {
  return request({
    url: 'manage/roster/getCalendar',
    method: 'get',
    type: 'params',
    data
  })
}
interface RosterList {
  rosterList: {}[];
  tenantId: number;
}
/**保存日历数据 */
export function updateCalender(data: RosterList): Promise<any> {
  return request({
    url: 'manage/roster/updateCalender',
    method: 'post',
    tenantId: data.tenantId,
    data: data.rosterList
  })
}